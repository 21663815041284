export const Language = {
  HK: 'zh-HK'
}

export const CountryCode = {
  MY: '+60',
  HK: '+852'
}

export const Currency = {
  MY: 'MYR',
  HK: 'HKD',
  SG: 'SGD'
}

export const StripeRegion = {
  MY: 'MY',
  HK: 'HK',
}

export const IdentificationMethod = {
  IC: "identificationCard",
  PASSPORT: "passportNumber"
}

export const AuthModalMode = {
  LOGIN: 'login',
  SIGNUP: 'signup',
  FORGOT_PASSWORD: 'forgot_password',
}

export const DefaultLocation = {
  lat: 3.0640394,
  lng: 101.60916369999995,
}

export const ParkitOfficeLocation = {
  lat: 3.1099682,
  lng: 101.5826116,
}

export const ParkitHkOfficeLocation = {
  lat: 22.3025883,
  lng: 114.1686721,
}

export const BreakpointsSize = {
  XSMALL: 'xsmall',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'xlarge',
}

export const RentParkingNearbyType = {
  NONE: 'NONE',
  WORK_HERE: 'WORK_HERE',
  STAY_HERE: 'STAY_HERE',
  STAY_OR_WORK_NEARBY: 'STAY_OR_WORK_NEARBY',
}

export const ParkingBuildingType = {
  LANDED: 'LANDED',
  APARTMENT: 'APARTMENT',
  COMMERCIAL: 'COMMERCIAL',
}

export const LeasePeriodType = {
  ANNUALLY: 'P12M',
  HALF_YEARLY: 'P6M',
  QUARTERLY: 'P3M',
  MONTHLY: 'P1M',
}

export const TerminateReason = {
  NOT_SATISFIED: 'Not satisfied with our service',
  FINANCIAL: 'Financial Reason',
  OFFICE_RELOCATION: 'Office Relocation',
  PROJECT_RELOCATION: 'Project Relocation',
  CHANGE_JOB: 'Changing jobs',
  CHANGE_MIND: 'Change of mind (Have not started parking yet)',
  RENTAL_RATE: 'Rental rate',
  PARKING_IN_USE: 'I am using the parking',
  OCCUPIED: 'Occupied / Other tenant is using the parking space',
  MOVING: 'Moving',
}

export const CardCollectionMethod = {
  ARRANGE_COLLECTION: 'Arrange collection from me',
  PASS_TO_OFFICE: 'I will pass it to ParkIt office',
  RETURNED_EARLIER: 'I have returned the access device(s) earlier',
  NO_DEVICE: 'I did not receive any access device(s) from ParkIt Team',
}

export const CardReturnMethod = {
  RETURN_AT_ADDRESS: 'Return to me at this address',
  DROP_MAILBOX: 'Please drop at mailbox',
  COLLECT_FROM_OFFICE: 'I will collect it at ParkIt office',
  NO_RETURN: 'No need for card return',
}

export const DashboardRouteId = {
  MY_VEHICLES: 'my-vehicles',
  LISTED_PARKING: 'listed-parking',
  TERMINATED_LISTING: 'terminated-listing',
  PAYOUT_HISTORY: 'payout-history',
  PAYOUT_METHOD: 'payout-method',
  RENTED_PARKING: 'rented-parking',
  RENTED_PARKING_DETAIL: 'rented-parking-detail',
  TERMINATED_RENTING: 'terminated-renting',
  MANAGE_SUBSCRIPTION: 'manage-subscription',
  PAYMENT_METHOD: 'payment-method',
  SUBSCRIBE_INSURANCE: 'subscribe-insurance',
  INVITE_FRIEND: 'invite-a-friend',
  MY_VOUCHERS: 'my-vouchers',
  PERSONAL_INFORMATION: 'personal-information',
  CHANGE_PASSWORD: 'change-password',
  NOTIFICATION: 'notification',
};

export const BlogCategoryTitle = {
  ALL: "All",
  DRIVER_TIPS: "Driver Tips",
  EXPLORING: "Exploring",
  MONETIZATION: "Monetization",
  TECH_TALK: "Tech Talk",
  ALL_ZHT: "全部",
  DRIVER_TIPS_ZHT: "車主小貼士",
  MONETIZATION_ZHT: "創造收入",
};

export const BlogRouteId = {
  FINDING_HOTSPOT_PARKING: 'finding-parking-in-a-hot-spot',
  KLANG_VALLEY_VIBES: 'klang-valley-vibes',
  INTERNATIONAL_ADVENTURES: 'buckle-up-for-international-adventures',
  FOODIES_PARADISE: 'foodies-paradise-and-parking-predicaments-in-klang-valley',
  MOST_DESIRED_PARKING: "most-desired-parking-passes-in-klang-valley",
  UNTAPPED_REVENUE_ASSET: "your-parking-space-an-untapped-revenue-asset",
  SEARCH_PARK: "from-search-to-park-with-parkit",
  SMART_PARKING: 'what-is-smart-parking',
  PALLET_PARKING: 'pallet-parking-a-tandem-parking-solution',
  GLOBAL_GLIMPSE: 'a-global-glimpse-into-smart-parking-solution-in-big-cities',
  COOL_TECH_CONCEPTS: 'cool-tech-concepts-that-supercharge-parking-space-capacities',
  FIRST_CHOICE: 'parkit-first-choice-for-renting-parking-space',
  TRUSTWORTHY_PARTNER: 'parkit-trustworthy-partner-for-renting-parking-space'
};

export const BlogTitle = {
  FINDING_HOTSPOT_PARKING: "Finding parking in a hot spot? We've got your back (and your playlist!)",
  KLANG_VALLEY_VIBES: "Klang Valley Vibes: food, arts, and more!",
  INTERNATIONAL_ADVENTURES: "Buckle up for international adventures: travelling with just your Malaysian driving license!",
  FOODIES_PARADISE: "Foodies' paradise and parking predicaments in Klang Valley",
  MOST_DESIRED_PARKING: "Most desired parking passes in Klang Valley",
  UNTAPPED_REVENUE_ASSET: "Your parking space: an untapped revenue asset",
  SEARCH_PARK: "From search to park with ParkIt",
  SMART_PARKING: "What is Smart Parking? Here's the components that make it tick.",
  PALLET_PARKING: "Pallet Parking: a tandem parking solution",
  GLOBAL_GLIMPSE: "A global glimpse into Smart Parking solution in big cities",
  COOL_TECH_CONCEPTS: "Cool tech concepts that supercharge parking space capacities",
  FIRST_CHOICE: "為什麼PARKIT是您出租車位的首選平台？",
  TRUSTWORTHY_PARTNER: "PARKIT 是您租用停車位最值得信賴的夥伴"
};

export const BlogDate = {
  FINDING_HOTSPOT_PARKING: "January 13, 2023",
  KLANG_VALLEY_VIBES: "February 21, 2023",
  INTERNATIONAL_ADVENTURES: "March 10, 2023",
  FOODIES_PARADISE: "April 18, 2023",
  MOST_DESIRED_PARKING: "May 5, 2023",
  UNTAPPED_REVENUE_ASSET: "June 14, 2023",
  SEARCH_PARK: "July 21, 2023",
  SMART_PARKING: "August 8, 2023",
  PALLET_PARKING: "September 19, 2023",
  GLOBAL_GLIMPSE: "October 6, 2023",
  COOL_TECH_CONCEPTS: "November 15, 2023",
  FIRST_CHOICE: "December 20, 2023",
  TRUSTWORTHY_PARTNER: "January 3, 2024"
};

export const BlogLength = {
  FINDING_HOTSPOT_PARKING: "36secs",
  KLANG_VALLEY_VIBES: "2mins",
  INTERNATIONAL_ADVENTURES: "3mins 45secs",
  FOODIES_PARADISE: "2mins",
  MOST_DESIRED_PARKING: "2mins 15secs",
  UNTAPPED_REVENUE_ASSET: "1min 25secs",
  SEARCH_PARK: "2mins",
  SMART_PARKING: "2mins 18secs",
  PALLET_PARKING: "58secs",
  GLOBAL_GLIMPSE: "2mins 44secs",
  COOL_TECH_CONCEPTS: "1min 52secs",
  FIRST_CHOICE: "2 分鐘閱讀",
  TRUSTWORTHY_PARTNER: "2 分鐘閱讀"
};

export const ParkingStatus = {
  ARCHIVED: "ARCHIVED",
  PROCESSING: "PROCESSING",
  BOOKED: "BOOKED",
  PRE_ALLOCATED: "PRE_ALLOCATED",
  ALLOCATED: "ALLOCATED",
  PAYING: "PAYING",
  PAID: "PAID",
  PRE_ACTIVE: "PRE_ACTIVE",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  VACANT: "VACANT",
  OCCUPIED: "OCCUPIED",
  OWNER_TERMINATING: "OWNER_TERMINATING",
  TERMINATING_PROCESSING: "TERMINATING_PROCESSING",
  TERMINATING: "TERMINATING",
  TERMINATION_APPROVED: "TERMINATION_APPROVED",
  TERMINATED: "TERMINATED",
  CANCELLED: "CANCELLED",
  REJECTED: "REJECTED"
};

export const ListingStatus = {
  INITIAL: "INITIAL",
  PROCESSING: "PROCESSING",
  REVISE: "REVISE",
  VACANT: "VACANT",
  ALLOCATED: "ALLOCATED",
  OCCUPIED: "OCCUPIED",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  TERMINATING_PROCESSING: "TERMINATING_PROCESSING",
  TERMINATING: "TERMINATING",
  TERMINATED: "TERMINATED",
  REJECTED: "REJECTED",
};

export const RentingStatus = {
  INITIAL: "INITIAL",
  PROCESSING: "PROCESSING",
  BOOKED: "BOOKED",
  PRE_ALLOCATED: "PRE_ALLOCATED",
  ALLOCATED: "ALLOCATED",
  PAYING: "PAYING",
  PAID: "PAID",
  PRE_ACTIVE: "PRE_ACTIVE",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  OWNER_TERMINATING: "OWNER_TERMINATING",
  TERMINATING_PROCESSING: "TERMINATING_PROCESSING",
  TERMINATION_APPROVED: "TERMINATION_APPROVED",
  TERMINATING: "TERMINATING",
  TERMINATED: "TERMINATED",
  CANCELLED: "CANCELLED",
  ARCHIVED: "ARCHIVED"
};

export const InsuranceStatus = {
  SUBSCRIBED: 'SUBSCRIBED',
  TERMINATING: 'TERMINATING',
  UNSUBSCRIBED: 'UNSUBSCRIBED',
};

export const ParkingAction = {
  RENTING_BOOKING: "Book",
  RENTING_PAYMENT: "Pay",
  FREEZE: "Freeze",
  UNFREEZE: "Unfreeze",
  CANCEL: "Cancel",
  TERMINATE: "Terminate",
  CANCEL_TERMINATION: "Cancel Terminate"
};

export const SubscriptionInterval = {
  WEEKLY: "WEEKLY",
  BI_WEEKLY: "BI_WEEKLY",
  MONTHLY: "MONTHLY",
  QUARTERLY: "QUARTERLY",
  HALF_YEARLY: "HALF_YEARLY",
  ANNUALLY: "ANNUALLY"
}

export const WalletTopUpPlatform = {
  PARKIT: 'PARKIT',
  DELIVERIT: 'DELIVERIT',
}

export const InvoiceLineItemServiceType = {
  ADMINISTRATION: 'ADMINISTRATION',
  DEPOSIT: 'DEPOSIT',
  DELIVERY_SERVICE: 'DELIVERY_SERVICE',
  INSURANCE_SUBSCRIPTION: 'INSURANCE_SUBSCRIPTION',
  RENTAL_SUBSCRIPTION: 'RENTAL_SUBSCRIPTION',
  MANDATORY: 'MANDATORY',
  UNSPECIFIC: 'UNSPECIFIC',
}

export const AFFILIATE_PARTNER = {
  MOTORIST: '65c32be487d7c20001755d27',
  JAGAAPP: '662f4e047f509500012db33f',
  CORPORATE5P: '663da191ef08b90001a4960b',
  SETIAGO: '66fea427e00d2f062b9958ed',
};
