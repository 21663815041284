export const PublicRoute = {
  Home: '/',
  About: '/about',
  Blog: '/blog',
  Faq: '/faq',
  Owners: '/owners',
  Parkers: '/parkers',
  BusinessSolutions: '/business-solutions',
  BulkListing: '/bulk-listing',
  BulkListingEnquiry: '/bulk-listing-enquiry',
  BulkRenting: '/bulk-renting',
  BulkRentingEnquiry: '/bulk-renting-enquiry',
  AddListing: '/add-listing',
  FindParking: '/find-parking/search',
  Reviews: '/reviews',
  JagaApp: '/jaga-app',
  SetiaGo: '/setia-go',
  PrivacyPolicy: '/privacy-policy',
  CancellationPolicy: '/cancellation-policy',
  InsurancePolicy: '/insurance-policy',
  ParkerTerms: '/parker-terms',
  OwnerTerms: '/owner-terms',
  ParkingArea: '/parking-area',
  ParkingDirectory: '/parking-directory',
};

export const Routes = {
  ...PublicRoute,
  ResetPassword: '/user/reset-password',
  Dashboard: '/dashboard',
  MyVehicles: '/dashboard/my-vehicles',
  RentedParking: '/dashboard/rented-parking',
  ManageSubscription: '/dashboard/manage-subscription',
  PaymentMethod: '/dashboard/payment-method',
  TerminatedRenting: '/dashboard/terminated-renting',
  ListedParking: '/dashboard/listed-parking',
  PayoutHistory: '/dashboard/payout-history',
  PayoutMethod: '/dashboard/payout-method',
  TerminatedListing: '/dashboard/terminated-listing',
  SubscribeInsurance: '/subscribe-insurance',
  InviteFriend: '/dashboard/invite-a-friend',
  MyVouchers: '/dashboard/my-vouchers',
  PersonalInformation: '/dashboard/personal-information',
  ChangePassword: '/dashboard/change-password',
  Notification: '/dashboard/notification',
  PayBooking: '/dashboard/rented-parking-detail',
  Checkout: '/dashboard/rented-parking-detail',
  TerminateListing: '/terminate-listing',
  TerminateRenting: '/terminate-renting' 
};